<template>
  <Teleport v-if="portalId" :to="portalId">
    <slot />
  </Teleport>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ to?: string | HTMLElement | null }>(), { to: "ui-portal" })
const portalId = ref<string | HTMLElement | null>()

watchEffect(() => {
  if (isServer) return

  // If the portal element doesn't exist, create it and append it to the body
  if (typeof props.to === "string" && !document.querySelector(props.to)) {
    const node = document.getElementById("app-root") || document.body

    node.appendChild(document.createElement(props.to))
  }

  portalId.value = props.to
})
</script>
